.modalClose
  display: none
  
.modalOpen 
  display: block
  position: fixed
  z-index: 9999
  top: 0
  left: 0
  background: rgba(0,0,0,0.5)
  width: 100%
  height: 100vh
  .wrapperMd
    width: 500px
    max-width: 90%
    margin: auto
    position: absolute
    height: auto
    background: #ffffff
    padding: 25px
    left: 50%
    top: 50%
    transform: translateY(-50%) translateX(-50%)
    border-radius: 10px
    box-shadow: 2px 2px 8px rgba(0,0,0,0.2)
  .wrapperLg
    width: 800px
    max-width: 90%
    margin: auto
    position: absolute
    max-height: 90%
    background: #ffffff
    padding: 25px
    left: 50%
    top: 50%
    transform: translateY(-50%) translateX(-50%)
    border-radius: 10px
    box-shadow: 2px 2px 8px rgba(0,0,0,0.2)
    overflow-y: scroll
  .close
    position: absolute
    font-size: 1.3rem
    right: 40px
    top: 35px
    cursor: pointer